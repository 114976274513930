<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="$tc('app.edit')" />
      <div class="page-content container-fluid">
        <FormComponent ref="update-provider" @submit="update">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-md-6">
                  <CardComponent variant="tertiary">
                    <template #header>
                      {{ $tc('app.general') }}
                    </template>
                    <InputComponent v-model="form.name" :label="$tc('app.name', 1)" required type="text"
                      :placeholder="$tc('app.name', 1)" />
                  </CardComponent>
                </div>
                <div class="col-md-6">
                  <CardComponent variant="tertiary">
                    <template #header>
                      {{ $tc('app.cost') }}
                    </template>
                    <div class="row">
                      <div class="col">
                        <InputComponent v-model="form.values.sms.vivo" label="Vivo" required type="text" v-money="money"
                          placeholder="Vivo" />
                      </div>
                      <div class="col">
                        <InputComponent v-model="form.values.sms.tim" label="Tim" required type="text" v-money="money"
                          placeholder="Tim" />
                      </div>
                      <div class="col">
                        <InputComponent v-model="form.values.sms.claro" label="Claro" required type="text"
                          v-money="money" placeholder="Claro" />
                      </div>
                      <div class="col">
                        <InputComponent v-model="form.values.sms.other" :label="$tc('app.other')" required type="text"
                          v-money="money" :placeholder="$tc('app.other')" />
                      </div>
                    </div>
                  </CardComponent>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ButtonComponent variant="secondary" class="float-right ml-2"
                    @click="$refs['update-provider'].submit()">
                    <span class="material-symbols-outlined">
                      save
                    </span>
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
        </FormComponent>
      </div>
    </template>
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import ProviderService from '@/services/admin/provider.service';
import FormComponent from '@/components/form/FormComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import { mask } from 'vue-the-mask';

export default {
  name: 'ShowProvider',
  components: {
    PageHeader,
    FormComponent,
    CardComponent,
    InputComponent,
    ButtonComponent,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      updating: false,
      form: {},
      searching: false,
      pages: 1,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      ProviderService.getProvider(this.$route.params.id).then(
        (provider) => {
          this.form = provider;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    update() {
      this.updating = true;
      ProviderService.updateProvider(this.form.id, this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm-component.supp-updated'),
              type: 'success',
            });
            this.$router.push('./');
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>
